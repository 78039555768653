import {initFocalSquare}    from "./ui/focal-point";
import {initH1}             from "./ui/h1";
import {initUi}             from "./init/ui";
import {initRoot}           from "./init/root";
import {initSvgEvents}      from "./simulation/events";
import {simulationElements} from "./simulation/basic";
import {initParameters}     from "./init/parameters/init";
import {loadParameters}     from "./init/parameters/read";
import {initSite}           from "./init/site";
import {initAnalytics}      from "./meta/analytics";
import {pushNode}           from "./simulation/nodes/data/operate";

const versions = {
  'v0.0.1': {
    assetPath: 'v0.0.1',
  }
}

window.onmessage = function (e) {
  setTimeout(() => {
    if (e.data === '[ play ]') {
      let url = 'https://lore.land';
      let r   = 13;
      if (/identity\/b1d7b187fab69795cdc3c2675d739405/.test(window.location.pathname)) {
        url = 'https://lore.land/plot/lamination-station';
        r   = 52;
      }
      pushNode({
        name:        e.data,
        kind:        'test',
        url:         url,
        r:           r,
        color:       'teal',
        stroke:      '#000000',
        strokeWidth: 1,
        colorindex:  3,
      })
    }
    if (e.data === 'adventure') {
      pushNode({
        name:        e.data,
        kind:        'test',
        url:         'https://rskrules.quest/?from=spwashi.com',
        r:           13,
        color:       '#a69261',
        stroke:      '#000000',
        strokeWidth: 1,
        colorindex:  3,
      })
    }
    window.spwashi.reinit();
  }, 1000);
};

async function registerServiceWorker(version = 'v0.0.1') {
  if (!('serviceWorker' in navigator)) { return; }

  const {assetPath} = versions[version];

  try {
    const registration = await navigator.serviceWorker.register(`/${assetPath}/service-worker.js`);
    console.log('Service Worker registered with scope:', registration.scope);
  } catch (e) {
    console.log('Service Worker registration failed:', e);
  }
}

export async function app() {
  let serviceWorkerRegistered = registerServiceWorker();

  window.spwashi = {};

  initAnalytics();
  initParameters();
  initRoot();
  initSite();

  // initialize context-sensitive parameters
  loadParameters(new URLSearchParams(window.location.search));

  // primary interactive elements
  initSvgEvents(simulationElements.svg);
  initFocalSquare();
  initH1();

  // progressive enhancement
  initUi(window.spwashi.initialMode);

  return Promise.all([serviceWorkerRegistered])
                .then(() => {

                });
}